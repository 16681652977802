
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車 スカイライン】日産のGT-RとGT-Rニスモの違いを解説！価格や性能の違いはある？
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            中古車市場で人気のGT-Rニスモは、GT-Rのハイエンドモデルです。日産自動車のモータースポーツ事業を担う「ニスモ」が開発を行っており、現行GT-Rとはスペックや価格面での違いがあります。<br>
            スカイラインやGT-Rニスモをはじめ、R32・R33・R34・R35型のGT-Rをお探しの方は、car shop MIXにご相談ください。希少な中古車、外車のプレミアムカーなどを中心に、ご希望の中古車探しをサポートいたします。
            <div class="section-title">
              <span >【中古車 スカイライン】GT-RとGT-Rニスモの違いとは？</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >中古車、特にスカイラインシリーズなどハイエンドモデルに特化した中古車販売・買取の株式会社 MIX YARDです。GT-R、GT-Rニスモなど多くの車好きがあこがれる限定車や希少車を中心に、国産車/外車、AT/MT問わず幅広く取り扱っています。現車確認の<a href="/contact/">お問い合わせ</a>もお気軽にお待ちしております。</p>
                    <p >中古車市場で人気を集めているGT-Rですが、そのハイエンドモデルがGT-Rニスモです。</p>
                    <p >こちらでは、現行GT-RとGT-Rニスモの違いについて解説します。</p>
                    <h3 class="heading-number" data-number="◆">GT-Rとは</h3>  
                    <p >日産自動車のGT-Rは、日本が世界に誇る高級スポーツカーです。1969年の登場以降、様々な記録を樹立し、カーレースの世界では伝説的な存在となっています。国産スポーツカーの代表格であり、車好きの間では抜群の知名度を誇るモデルです。</p>
                    <p >なお、現行のGT-Rは「R35」と呼ばれます。レースで数々の記録を樹立した「スカイラインGT-R」の事実上の後継車であり、中古車市場において高値で取引されている「R34」の次に誕生したモデルです。スカイラインの名を冠さなくなった今なお、世界中に熱狂的なファンが存在します。</p>
                    <h3 class="heading-number" data-number="◆">GT-Rニスモとは</h3>  
                    <p >GT-Rニスモは、GT-Rシリーズのハイエンドモデルのロードカーです。「ニスモ（NISMO）」という名称は「NISSAN MOTORSPORTS INTERNATINAL」の略称であり、同名の企業の名前を冠しています。</p>
                    <p >会社としてのニスモは日産自動車傘下ではありますが、別の組織です。スポーツカーの開発からレース参戦、レーサーの育成やプロモーションイベントの実施まで、日産自動車のモータースポーツ事業を一手に引き受けています。</p>
                    <p >日産直系のワークスチームとして数々のタイトルを獲得しており、長年蓄積されたノウハウや技術を車両・パーツの開発に活かした車づくりが魅力です。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-gtr-amg/gt-r-gt-r-nismo-difference.jpg" alt="中古車市場で人気のスカイライン GT-RとGT-Rニスモの違いとは" width="640" height="480" style="height: auto;">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">GT-RとGT-Rニスモの違い</h3>  
              <p >GT-RとGT-Rニスモは、どちらも「GT-R」というスカイラインシリーズに属していることに変わりはありません。</p>
              <p >GT-Rは、1969年に日産自動車が世に送り出した「スカイラインGT-R」の流れを汲んだ後継車です。一方、GT-RニスモもGT-Rの流れは汲んでいるものの、日産自動車のモータースポーツ事業を担う「日産モータースポーツ＆カスタマイズ株式会社」の「NISMO RACING事業部」が開発を行っています。</p>
              <p >こちらでは、それぞれの違いを詳しく見ていきましょう。</p>

              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>特徴</h4> 
              <p >GT-Rニスモには強度と軽さを兼ね備えた「鍛造アルミホイール」が採用されています。カーボン製のルーフやエンジンフードなど、贅沢なボディチューニングがなされているのが特徴です。</p>
              <p >また、エンジンやエンジンカバーもニスモ専用のものが用いられています。</p>
              <p >さらに、現行のGT-Rの重量が1,760～1,770kgなのに対し、GT-Rニスモは1,720kgほどです。ルーフやエンジンフード、フロントフェンダーに軽くて丈夫なカーボンを使用することで、軽量化に成功しています。</p> 
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>スペック</h4>
              <p >GT-Rの最高出力は570PS、最大トルクが65.0kgf・mに対し、GT-Rニスモは専用タービンやコンピューターを採用することで最高出力600PS、最大トルク66.5kgf・mを実現しています。GT-R自体とても高いスペックではありますが、GT-Rニスモはそれを凌駕するパワーを持っています。</p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>価格</h4>
              <p >様々なカーボンパーツを取り入れたことにより、GT-Rニスモの価格は現行GT-Rの約2倍もあります。</p>
              <p >参考までに、現行GT-Rの新車価格は約1,200万円ですが、GT-Rニスモの価格は約2,400万円です。中古車の場合も同様で、GT-Rニスモに現行GT-Rよりも1,000万以上高い値段がつくケースも少なくありません。</p>

              <div class="c-btn">
                  <router-link to="/jigyo/oldCars" class="c-btn-anchor">car shop MIXで取り扱う中古車のメーカーや車種</router-link>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >【スカイライン】GT-RやGT-Rニスモの中古車探しはcar shop MIXまで！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >スカイラインGT-Rは日産自動車が手がける高級スポーツカーです。</p>
                    <p >一方、GT-Rニスモは、日産自動車のモータースポーツ事業を担う「日産モータースポーツ＆カスタマイズ株式会社」の「NISMO RACING事業部」が手がけています。</p>
                    <p >GT-RニスモはGT-Rのハイエンドモデルであり、価格差は新車、中古車ともに約2倍程度です。丈夫で軽いカーボンや専用エンジンなど贅沢なボディチューニングが施されており、軽量化に成功したことで加速性能が向上しているのも魅力だといえるでしょう。</p>
                    <p >GT-RやGT-Rニスモは中古車市場でも高い人気を誇り、状態のよいものはすぐに売れてしまう可能性があります。スカイラインGT-RやGT-Rニスモの中古車をお探しなら、car shop MIXをご利用ください。スカイラインやメルセデスAMG、ポルシェ カイエンなど、一般市場ではなかなか手に入らない限定車や希少車を、幅広くラインナップしております。</p>
                    <p >弊社に在庫がない場合も、オークションの代行などによりご希望の車をお探しすることが可能です。全国各地のお客様への納車に対応しておりますので、まずはお気軽にお問い合わせください。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="スカイラインGT-RやGT-Rニスモの中古車をお探しなら！白色のGT-Rニスモ" src="@/assets/img/column-gtr-amg/white-gt-r-nismo.jpg" width="640" height="480" style="height: auto;">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/contact" class="c-btn-anchor">お問い合わせ</router-link>
              </div>
              <div class="c-btn">
                <a href="https://www.worldingsysdom.com/stock_info/list/car" target="_blank" rel="noopener noreferrer nofollow" class="c-btn-anchor">国産車・外車の希少な中古車 在庫はこちら</a>
              </div>
            </div>
          </div>
          <column-footer :columnListTitle="'中古車選びや人気モデルのスカイラインGT-R・AMGに関するコラム'" :title="'GT-RやGT-Rニスモ・スカイラインの中古車をお探しならcar shop MIXへ'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-gtr-amg/head-mv03.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}


</style>
